import Icon from "components/Icon";
import React, { useContext, useEffect, useState } from "react";

import media from "assets/images/women.jpeg";
import formatTime from "utils/formatTime";
import { useUsersContext } from "context/usersContext";
import maybeIcon from "../../../assets/images/maybe-icon.png";
import axios from "axios";
import env from "config";
import continuepng from "../continue.png";
import tryagainpng from "../tryagain.png"
import { marked } from "marked";
import ContentRenderer from "./ContentRenderer";
import { PromptsContext } from "context/promptsContext";
import AddPromptModal from "components/AddPromptModal";
import slidesvg from "../../../assets/images/slide.svg"

const Convo = ({
  lastMsgRef,
  messages: allMessages,
  userId,
  agencyInfo,
  submitNewMessage,
  copySelectedFiles,
  selectedFiles,
  UploadOnKB,
  asistantSelected,
  assistantData
}) => {
  const BASE_URL = `${env.API_URL}/v1`;
  const dates = Object.keys(allMessages);
  const { addNewMessage, users, enableChat, SSO, updateMemory, createMemory, showToast, fetchMessageResponse, setLoadingStateslide, loadingStateslide } = useUsersContext();
  const activeConvo = users.find((user) => user.id === userId);
  const [tooltipStates, setTooltipStates] = useState({});
  const [showTooltip, setShowTooltip] = useState(false);
  const [loadingState, setLoadingState] = useState({});
  const [isOpenAddPromptModal, setIsAddPromptModal] = useState(false);
  const [sendtoPromptModel, setSendtoPromptModel] = useState(false);
  const { promptsData, getPrompts, } = useContext(PromptsContext);
  function handleDownload(event, url) {
    event.preventDefault();  
    console.log('Downloading from:', url);
    window.location.href = url;   
}
  const SubmitPrompt = (user_msg, prompt) => {
    addNewMessage(userId, user_msg, prompt);
    enableChat();
  };
  const copyToClipboard = (content, id) => {
    const htmlContent = marked(content);

    // Create a temporary container for the HTML content
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlContent;

    tempDiv.style.position = 'absolute';
    tempDiv.style.left = '-9999px';
    document.body.appendChild(tempDiv);

    const range = document.createRange();
    range.selectNodeContents(tempDiv);
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);

    try {
      document.execCommand('copy');
      console.log('Content copied to clipboard!');
    } catch (err) {
      console.error('Failed to copy content: ', err);
    }

    // Clean up by removing the temporary element
    document.body.removeChild(tempDiv);
  };

  const [questions, setQuestions] = useState([])
  const getQuestions = async () => {
    await axios
      .get(BASE_URL + `/chat/getquestions?agency_id=${agencyInfo?._id}`).
      then((response) => {
        setQuestions(response.data.data)
      })
      .catch(function (error) { });
  };
  useEffect(() => {
    getQuestions();
  }, [])

  const handleMemory = async (content, msgIndex) => {
    setLoadingState((prev) => ({ ...prev, [msgIndex]: true }));
    let memory = await updateMemory(SSO?.id, content)
    if (memory.err) {
      memory = await createMemory(SSO?.id, content);
    }
    setLoadingState((prev) => ({ ...prev, [msgIndex]: false }));
    showToast('Memory updated', 'success');
  }

  const justCloseIt = () => {
    setIsAddPromptModal(false);
  }
  const addTask = (message) => {
    setSendtoPromptModel(message);
    setIsAddPromptModal(true);
  }

  const submitslide = async (message, msgIndex) => {
    setLoadingStateslide((prev) => ({ ...prev, [msgIndex]: true }));
    const payload = {
      data: {
        conversation_id: userId,
        profile_id: SSO?.id,
        user_name: SSO?.chat_username,
        prompt: `Review and convert the above: Generate a JSON request that creates a Google Slides presentation with multiple slides. Each slide should include: A createSlide action that specifies a unique objectId for the slide, as well as placeholderIdMappings to set custom objectIds for the title and body placeholders. The slideLayoutReference should use layouts such as "TITLE_AND_BODY" or "TITLE_AND_TWO_COLUMNS" as appropriate. After each createSlide action, add insertText actions for the title and body text. Use the objectIds defined in placeholderIdMappings for the title and body placeholders in these insertText actions. Here’s the JSON structure to follow: json { “requests”: [ { “createSlide”: { “objectId”: “PAGE_ID_1", “slideLayoutReference”: { “predefinedLayout”: “TITLE_AND_TWO_COLUMNS” }, “placeholderIdMappings”: [ { “layoutPlaceholder”: { “type”: “TITLE”, “index”: 0 }, “objectId”: “TITLE_ID_1" }, { “layoutPlaceholder”: { “type”: “BODY”, “index”: 0 }, “objectId”: “BODY_ID_1" }, { “layoutPlaceholder”: { “type”: “BODY”, “index”: 1 }, “objectId”: “BODY_ID_1_SECOND_COLUMN” } ] } }, { “insertText”: { “objectId”: “TITLE_ID_1”, “text”: “Welcome to Our Company” } }, { “insertText”: { “objectId”: “BODY_ID_1", “text”: “Our company has a rich history of innovation and success.” } }, { “insertText”: { “objectId”: “BODY_ID_1_SECOND_COLUMN”, “text”: “Key achievements include:\n- Innovation awards\n- Expansion to new markets\n- Excellent customer feedback” } }, ... ] } Generate at least three slides with unique content for each. Use TITLE_AND_BODY for some slides and TITLE_AND_TWO_COLUMNS for others. Each slide should have title text and body text, and if using TITLE_AND_TWO_COLUMNS, include text for both body columns. Important only send the JSON output`,
        file_ids: [],
      },
    }
    await axios
      .post(BASE_URL + `/chat/Createslide`, payload)
      .then((response) => {
        console.log('response.data.data.promptresponse.data.data.promptresponse.data.data.prompt', response.data.data.prompt)
        // return 0
        const data = {
          userId: userId,
          response: { content: `Please convert the following list of file links into a user-friendly, readable format. For each link, display a descriptive text like 'Click to download the [file type]' and make the link clickable so that when the user clicks it, the file automatically starts downloading. For files that are not meant for downloading (such as web pages), do not include the 'download' functionality. Ensure the output is formatted in plain text and is easy for users to understand. ${response.data.data.prompt}` },
          messagetype: "notimage",
          fileData: [],
          assistant: {}
        };
        fetchMessageResponse(data, msgIndex);
      })
      .catch(function (error) {

      });
  }

  return dates?.map((date, dateIndex) => {
    const messages = allMessages[date];

    return (
      <div key={dateIndex} style={{ position: "relative", overflowY: "auto", overflowX: "hidden", height: "80vh" }}>
        <div className="chat__date-wrapper">
          <span className="chat__date"> {date}</span>
        </div>


        {messages.length == 0 && !activeConvo.fromPrompt ? (
          <div className="">

            <div className="iconBox">
              <img src={agencyInfo?.Customization?.chatapp_logo ? agencyInfo?.Customization?.chatapp_logo : maybeIcon} alt="maybe icon" style={{ height: '100px' }} />
              <p>
                <b style={{ color: "black" }}>
                  {agencyInfo?.Customization ? agencyInfo?.Customization?.chatapp_text : 'How can Brand AI help you today?'}
                </b>
              </p>
            </div>
            <div className="row">
              {questions.length > 0 && questions.map((question, ind) => (
                <div className="col-md-6 pb-30px" key={ind}>
                  <div className="prompt_box" onClick={() =>
                    SubmitPrompt(
                      question.user_message,
                      question.prompt
                    )
                  }>
                    <div className="prompt_text">
                      <b>{question.user_message}</b>
                    </div>
                    <Icon id="send" className="chat__input-icon prompt_icon" />
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className="chat__msg-group">
          {messages?.map((message, msgIndex) => {
            const htmlContent = marked(message.content);
            const assignRef = () =>
              dateIndex === dates.length - 1 && msgIndex === messages.length - 1
                ? lastMsgRef
                : undefined;
            return (
              <>

                {message.image ? (
                  <div className={`chat__msg chat__img-wrapper ${message.sender ? "chat__msg--rxd" : "chat__msg--sent"}`}
                    ref={assignRef()}
                  >
                    {/* <img src={media} alt="" className="chat__img" />
                    <span className="chat__msg-footer">
                      <span>{formatTime(message.time)}</span>
                      {!message.sender && (
                        <Icon
                          id={
                            message?.status === "sent"
                              ? "singleTick"
                              : "doubleTick"
                          }
                          aria-label={message?.status}
                          className={`chat__msg-status-icon ${message?.status === "read"
                            ? "chat__msg-status-icon--blue"
                            : ""
                            }`}
                        />
                      )}
                    </span> */}
                  </div>
                ) : message.sender ? (
                  <p className="chat__msg chat__msg--rxd" style={{ marginTop: "5px", textAlign: "left" }} ref={assignRef()}>
                    <div className="v3_airesponse">
                      <div>
                        <img className="v3_imgchat" src={agencyInfo?.Customization?.chatapp_logo ? agencyInfo?.Customization?.chatapp_logo : maybeIcon} width={50} alt="maybe icon" />
                      </div>
                      <div className="v3_top-10px">

                        <ContentRenderer index={msgIndex} htmlContent={htmlContent} />
                        {/* Add the copy button */}
                        <div className="topsettingonresponse d-flex justify-content-between "  >
                          <div className="w-50 d-flex justify-content-start">
                            <button
                              onMouseEnter={() => setShowTooltip(msgIndex)}
                              onMouseLeave={() => setShowTooltip(null)}
                              onClick={() => copyToClipboard(message.content, msgIndex)}
                              aria-label="Copy"
                              style={{ marginRight: "10px" }}
                            >
                              <div style={{ position: "relative" }}>
                                <div class="hover-text">
                                  <Icon id="v3_copy" />

                                  <span class="tooltip-text">{agencyInfo?.Customization?.copyicon_text ? agencyInfo?.Customization?.copyicon_text : "Copy"}</span>
                                </div>
                              </div>
                            </button>

                            <button
                              onMouseEnter={() => setShowTooltip(msgIndex)}
                              onMouseLeave={() => setShowTooltip(null)}
                              aria-label="Create Table"
                              onClick={() => submitNewMessage("Convert this response to the table format", "notsavetodb", "notimage")} style={{ marginRight: "10px" }}>
                              <div style={{ position: "relative" }}>
                                <div class="hover-text">
                                  <Icon id="table" />

                                  <span class="tooltip-text">{agencyInfo?.Customization?.table_text ? agencyInfo?.Customization?.table_text : "Create Table"}</span>
                                </div>
                              </div>
                            </button>
                            <button
                              aria-label="Create Slides"
                              onClick={() => {

                                submitslide(message, msgIndex); // Pass the collected messages to the function
                              }}
                              style={{ marginRight: "10px" }}
                            >


                              <div style={{ position: "relative" }}>
                                <div className="hover-text">
                                  {loadingStateslide[msgIndex] ? (
                                    <Icon id="spinner" />
                                  ) : (
                                    <img src={slidesvg} width={"21px"} alt="" />
                                  )}

                                  <span className="tooltip-text">Create Slides</span>
                                </div>
                              </div>
                            </button>

                            {asistantSelected.assistant_name === "Brand AI" && (
                              <button aria-label="Create Memory" style={{ marginRight: "10px" }} onClick={() => { handleMemory(message?.content, msgIndex) }}>
                                <div style={{ position: "relative" }}>
                                  <div class="hover-text">
                                    {loadingState[msgIndex] ? (
                                      <Icon id="spinner" />
                                    ) : (
                                      <Icon id="v3_brain" />
                                    )}
                                    <span class="tooltip-text">
                                      {agencyInfo?.Customization?.brainicon_text ? agencyInfo?.Customization?.brainicon_text : "Create Memory"}
                                    </span>
                                  </div>
                                </div>
                              </button>
                            )}
                            <button
                              onMouseEnter={() => setShowTooltip(msgIndex)}
                              onMouseLeave={() => setShowTooltip(null)} className="hovercolor" style={{ marginRight: "10px" }} onClick={() => submitNewMessage("Rewrite the response", "notsavetodb", "notimage")} >
                              <div style={{ position: "relative" }}>
                                <div class="hover-text">
                                  <Icon id="v3_refresh" />

                                  <span class="tooltip-text">{agencyInfo?.Customization?.tryagain_text ? agencyInfo?.Customization?.tryagain_text : "Try Again"}</span>
                                </div>
                              </div>
                            </button>
                            <button
                              onMouseEnter={() => setShowTooltip(msgIndex)}
                              onMouseLeave={() => setShowTooltip(null)} className="hovercolor" onClick={() => submitNewMessage("Continue", "notsavetodb", "notimage")} >
                              <div style={{ position: "relative" }}>
                                <div class="hover-text">
                                  <Icon id="v3_continue" />

                                  <span class="tooltip-text">{agencyInfo?.Customization?.continue_text ? agencyInfo?.Customization?.continue_text : "Continue"}</span>
                                </div>
                              </div>
                            </button>
                          </div>

                        </div>
                        {/* {showTooltip === msgIndex && (
                          <div className="chat__tooltip">{tooltipStates[msgIndex] || 'Copy!'}</div>
                        )} */}
                        <span className="chat__msg-footer">
                          {formatTime(message.time)}
                        </span>
                        <span className="chat__msg-filler mt-3"></span>
                      </div>
                    </div>
                  </p>
                ) : (
                  <div style={{ position: "relative", margin: "20px  10px 10px 10px" }} >
                    <div style={{ textAlign: "right" }}>
                      <p className="chat__msg chat__msg--sent position-relative" ref={assignRef()}>
                        <span id={`id_${msgIndex}`}>{message.content}</span>
                        <span className="chat__msg-filler"> </span>

                        <span className="chat__msg-footer">
                          <span> {formatTime(message.time)} </span>
                          <Icon
                            id={
                              message?.status === "sent"
                                ? "singleTick"
                                : "dobuleTickWhite"
                            }
                            aria-label={message?.status}
                            className={`chat__msg-status-icon ${message?.status === "read"
                              ? "chat__msg-status-icon--blue"
                              : ""
                              }`}
                          />
                        </span>
                        <div class="iconWrapper">
                          <button
                            onMouseEnter={() => setShowTooltip(msgIndex)}
                            onMouseLeave={() => setShowTooltip(null)}
                            onClick={() => copyToClipboard(message.content, msgIndex)}
                            aria-label="Copy message"
                            style={{ marginRight: "10px" }}
                          >
                            <div style={{ position: "relative" }}>
                              <div class="hover-text"><Icon id="v3_copy" />
                                {agencyInfo?.Customization?.copyicon_text &&
                                  <span class="tooltip-text">{agencyInfo?.Customization?.copyicon_text ? agencyInfo?.Customization?.copyicon_text : "Copy"}</span>}
                              </div>
                            </div>
                          </button>
                          {asistantSelected.assistant_name === "Brand AI" && (
                            <button aria-label="Create Memory" onClick={() => { handleMemory(message?.content, msgIndex) }}>
                              <div style={{ position: "relative" }}>
                                <div class="hover-text">
                                  {loadingState[msgIndex] ? (
                                    <Icon id="spinner" />
                                  ) : (
                                    <Icon id="v3_brain" />
                                  )}
                                  <span class="tooltip-text">
                                    {agencyInfo?.Customization?.brainicon_text ? agencyInfo?.Customization?.brainicon_text : "Create Memory"}
                                  </span>
                                </div>
                              </div>
                            </button>
                          )}
                        </div>
                      </p>

                      {msgIndex === messages.length - 2 &&
                        <span
                          style={{ cursor: "pointer", position: "relative" }}
                          onMouseEnter={() => setShowTooltip(msgIndex)}
                          onMouseLeave={() => setShowTooltip(null)}
                          onClick={() => addTask(message.content)}
                          className="hover-text"
                        > <Icon id={"v3_4dots"} />

                          <span class="tooltip-text">
                            Create a Task
                          </span>
                        </span>}

                      <div style={{ margin: '20px 0' }}></div>

                      <div className="">
                        {message?.file_ids?.map((item, index) => (
                          <div key={index} style={{ marginBottom: "21px", display: "flex", alignItems: "center", }}>
                            <div className="file_upload_fileId">
                              <span style={{ marginRight: "10px" }}>
                                <Icon id="file" />
                              </span>
                              {item?.name?.split('')?.length > 10 ? `${item?.name.split('/')[item?.name.split('/').length - 1]?.substring(0, 10)}...` : item?.name}
                            </div>
                            {console.log('file_typefile_type', message.file_type, !item.name.includes('.png'))}
                            {!(message?.file_type === 'KB' || item.name.includes('.png') || item.name.includes('.jpg')) && (
                              <span style={{ marginLeft: "13px", cursor: "pointer" }} onClick={() => UploadOnKB(item)}>
                                <Icon id="uploadFile" />
                              </span>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}

              </>
            );
          })}
          {activeConvo && activeConvo?.typing ? (
            <div class="my message " id="gotovie" style={{ marginTop: "30px", marginLeft: "20px" }}>
              <span class="jumping-dots">
                <span class="dot-1"></span>
                <span class="dot-2"></span>
                <span class="dot-3"></span>
              </span>
            </div>
          ) : ("")}


        </div>
        <AddPromptModal
          isModalOpen={isOpenAddPromptModal}
          categories={promptsData?.prompt_category?.filter(obj => obj.type !== 'super-admin')}
          subCategories={promptsData?.prompt_sub_category?.filter(obj => obj.type !== 'super-admin')}
          justCloseIt={justCloseIt}
          getUpdatePromptsData={getPrompts}
          autoloadfirsttext={sendtoPromptModel}
          assistantData={assistantData}
          asistantSelected={asistantSelected}
        />
      </div>

    );
  });
};

export default Convo;
