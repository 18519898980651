import React, { useEffect, useRef, useState } from "react";
import Icon from "components/Icon";
import { useUsersContext } from "context/usersContext";
import { useHistory } from "react-router-dom";
import DeleteChatModal from "components/DeleteChatModal";
import axios from "axios";
import env from "config";
import { usePromptsContaxt } from "context/promptsContext";
import EditPromptModal from "components/EditPromptModal";
import AddCategoryModal from "components/AddCategoryModal";
import AddSubCategoryModal from "components/AddSubCategoryModal";
import SharePromptModal from "components/SharePromptModal";
const BASE_URL = `${env.API_URL}/v1`;
const Modal = ({ isOpen, onClose, children }) => {
  return (
    <>
      {isOpen && (
        <div className="modal-overlay" onClick={onClose}>
          <div
            className="modal-content text-center" style={{ maxWidth: '660px' }}
            onClick={(e) => e.stopPropagation()}
          >
            {children}
          </div>
        </div>
      )}
    </>
  );
};
const SharedPrompt = ({ promptsData, contact, onDelete, index, openDropdownIndex, setOpenDropdownIndex, favourites, getFavourites }) => {
  const { addNewMessage, setUserAsUnread, refresh, showToast, setOnFromPrompt, SSO, getLocationUsers, locationUsers, ChatSubmit } = useUsersContext();
  const { handleRefreshData } = usePromptsContaxt();
  const history = useHistory();

  const [askToDeleteModal, setAskToDeleteModal] = useState(false);
  const [askToShareModal, setAskToShareModal] = useState(false);
  const [selectedContact, setSelectedContact] = useState();
  const [isEditPrompt, setIsEditPrompt] = useState();
  const [isOpenEditPromptModal, setIsOpenEditPromptModal] = useState(false);
  const [showAddCategoryModal, setShowAddCategoryModal] = useState(false);
  const [showAddSubCategoryModal, setShowAddSubCategoryModal] = useState(false);

  const isOpen = openDropdownIndex === index;
  const toggleDropdown = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setOpenDropdownIndex(isOpen ? null : index);
  };

  const askToDelete = async (e, contact) => {
    e.stopPropagation();
    e.preventDefault();
    setSelectedContact(contact);
    setAskToDeleteModal(true);
  }
  const askToShare = async (e, contact) => {
    e.stopPropagation();
    e.preventDefault();
    setSelectedContact(contact);
    setAskToShareModal(true);
  }

  const handleDeleteClick = async () => {
    const response = await axios.get(`${BASE_URL}/chat/promptDelete?_id=${selectedContact._id}`);
    if (response) {
      // setUsers((users) => users.filter((user) => user.id !== selectedContact?.id));
      const redirectUrl = `/`;
      history.push(redirectUrl);
      justCloseIt();
      handleRefreshData();
    } else {
      showToast('Something went wrong!')
    }
  };

  const moveToCategoryHandler = async (e, categoryData) => {
    const payload = {
      promptId: contact._id,
      category: categoryData?.category_id,
      sub_category: categoryData?.subCategory_id
    };
    return await axios
      .put(BASE_URL + "/chat/shareprompt", payload)
      .then(async function (response) {
        handleRefreshData();
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const handleFavourite = async (e, contact) => {
    e.stopPropagation();
    e.preventDefault();
    const response = await axios.get(`${BASE_URL}/chat/add/favourite?chat_id=${contact?.id}&user_id=${SSO?.userUUID}&location_id=${SSO?.id}`);
    // await axios.get(`${BASE_URL}/remove/favourite?id=${contact?.id}`);
    if (response) {
      getFavourites();
      justCloseIt();
    }
  }

  const justCloseIt = () => {
    setAskToDeleteModal(false);
    setOpenDropdownIndex(false);
    setAskToShareModal(false);
    setIsOpenEditPromptModal(false);
    setShowAddCategoryModal(false);
    setShowAddSubCategoryModal(false);
  }

  const handleSubmit = async (e, user_msg, prompt) => {
    e.preventDefault();
    setOnFromPrompt(SSO?.userUUID);
    const payload = {
      locationId: SSO?.id,
      username: SSO?.chat_username,
    };
    // enableChat();

    const temp = await ChatSubmit(payload, true);

    if (!temp?.response) {
      refresh(temp);

      const chatUrl = `/chat/${temp.id}`;
      setUserAsUnread(temp.id);
      history.push(chatUrl);

      // const SubmitPrompt = (user_msg, prompt) => {
      addNewMessage(temp.id, user_msg, prompt, "savetodb");
      // };
    } else {
      showToast("Something went wrong!");
    }
  };

  const handleEditPrompt = (e, prompt) => {
    e.stopPropagation();
    e.preventDefault();
    setIsEditPrompt(prompt)
    setIsOpenEditPromptModal(true)
  }

  const handleAddPromptCategory = () => {
    setShowAddCategoryModal(true);
  }

  const handleAddPromptSubCategory = () => {
    setShowAddSubCategoryModal(true);
  }
  return (
    <>
      <div className="prompt_box_sidebar"
        onClick={(event) => handleSubmit(event, contact.title, contact.prompt_value)} >
        <div className="prompt_text">
          {contact.title}
        </div>
        <div className="sidebar-contact__bottom-content">
          <div className="sidebar-contact__time">
            <Dropdown
              showDropdown={openDropdownIndex === index}
              toggleDropdown={(e) => toggleDropdown(e, index)}
              onDeleteClick={(e) => askToDelete(e, contact)}
              onShareClick={(e) => askToShare(e, contact)}
              editbutton={(e) => handleEditPrompt(e, contact)}
              promptsData={promptsData}
              moveToCategoryHandler={moveToCategoryHandler}
              favbutton={(e) => handleFavourite(e, contact)}
              handleAddPromptCategory={handleAddPromptCategory}
              handleAddPromptSubCategory={handleAddPromptSubCategory}
            />
          </div>
        </div>
      </div>

      <DeleteChatModal
        isModalOpen={askToDeleteModal}
        contact={selectedContact}
        yesDelete={handleDeleteClick}
        justCloseIt={justCloseIt}
        type="prompt"
      />
      <SharePromptModal
        isModalOpen={askToShareModal}
        contact={selectedContact}
        justCloseIt={justCloseIt}
        getLocationUsers={getLocationUsers}
        locationUsers={locationUsers}
      />
      {
        isEditPrompt && (
          <EditPromptModal
            isModalOpen={isOpenEditPromptModal}
            categories={promptsData?.prompt_category?.filter(obj => obj.type !== 'super-admin')}
            subCategories={promptsData?.prompt_sub_category?.filter(obj => obj.type !== 'super-admin')}
            justCloseIt={justCloseIt}
            getUpdatePromptsData={handleRefreshData}
            editadata={isEditPrompt}
          />
        )
      }

      <Modal isOpen={showAddCategoryModal || showAddSubCategoryModal}>
        <div className="scrollable-modal-content">
          {showAddCategoryModal && (
            <AddCategoryModal onClose={justCloseIt} refreshData={handleRefreshData} />
          )}
          {showAddSubCategoryModal && (
            <AddSubCategoryModal
              onClose={justCloseIt}
              categories={promptsData?.prompt_category?.filter(obj => obj.type !== 'super-admin')}
              refreshData={handleRefreshData}
            />
          )}
        </div>
      </Modal>
    </>
  );
};

const Dropdown = ({ favbutton, promptsData, showDropdown, toggleDropdown, editbutton, onDeleteClick, onShareClick, moveToCategoryHandler, handleAddPromptCategory, handleAddPromptSubCategory }) => {

  const [showSubmenu, setShowSubmenu] = useState(false);
  const [subcategories, setsubcategories] = useState([]);
  const dropdownRef = useRef(null);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
  const toggleSubmenu = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setShowSubmenu(true);
    setSubCatOpen(false)

  };

  const handleMenu = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const { clientX, clientY } = e;
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;
    const dropdownWidth = 200; // Approximate width of the dropdown menu
    const dropdownHeight = 150; // Approximate height of the dropdown menu

    let adjustedLeft = clientX;
    let adjustedTop = clientY;

    // Adjust horizontal position if the dropdown would overflow the viewport
    if (clientX + dropdownWidth > viewportWidth) {
      adjustedLeft = viewportWidth - dropdownWidth - 10; // 10px margin from the edge
    }

    // Adjust vertical position if the dropdown would overflow the viewport
    if (clientY + dropdownHeight > viewportHeight) {
      adjustedTop = viewportHeight - dropdownHeight - 10; // 10px margin from the edge
    }

    setDropdownPosition({ top: adjustedTop, left: adjustedLeft });
    toggleDropdown(e);
    setShowSubmenu(false);
    setSubCatOpen(false)

  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      toggleDropdown(event);
    }
  };

  useEffect(() => {
    if (showDropdown) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showDropdown]);

  const [subCatOpen, setSubCatOpen] = useState(false)
  const getsubcateogories = (e, id, currentname) => {
    e.stopPropagation();
    e.preventDefault();
    const matches = [];

    for (let category of promptsData.prompt_category) {
      for (let subCategory of promptsData.prompt_sub_category) {
        if (subCategory.category_id === id && category.name === currentname) {
          matches.push({
            category: category.name,
            subCategory: subCategory.name,
            category_id: category._id,
            subCategory_id: subCategory._id,
          });
        }
      }
    }
    setsubcategories(matches)
    setSubCatOpen(true)
    // alert("Ff")
  }
  return (
    <div style={{position:"relative"}}>
      <span style={{ height: "100px", overflowY: "scroll", padding: 0 }} className="userChatIcon truncateUserName" onClick={(e) => handleMenu(e)}>
      <Icon id="ellipsis" />
      {showDropdown && (
        <div ref={dropdownRef} className="settingMenu"
          style={{
            position: "fixed",
            top: dropdownPosition.top,
            left: dropdownPosition.left,
            zIndex: 1000,
            backgroundColor: "white",
            boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
            borderRadius: "4px",
          }}>
          <div style={{ padding: "5px 0px" }} onClick={favbutton}>Favourite</div>
          <div style={{ padding: "5px 0px" }} onClick={editbutton}>Edit</div>
          <div style={{ padding: "5px 0px" }} onClick={onShareClick}>Share</div>
          <div style={{ padding: "5px 0px" }} onClick={toggleSubmenu}>{`Move >`}</div>
          <div style={{ padding: "5px 0px", color: "red" }} onClick={onDeleteClick}>Delete</div>

          {showSubmenu && (
            <div className="submenu">
              <div onClick={handleAddPromptCategory}> + Add new </div>
              {promptsData.prompt_category.map((item) => (
                <>
                  {item.type === "agency-admin" &&
                    <div onClick={(e) => getsubcateogories(e, item._id, item.name)}>{item.name}</div>
                  }
                </>
              ))}
            </div>
          )}

          {subCatOpen && showSubmenu &&
            <div className="submenu-1">
              <div onClick={handleAddPromptSubCategory}> + Add new </div>
              {subcategories.map((item) => (
                <div onClick={(e) => moveToCategoryHandler(e, item)}>{item.subCategory}</div>
              ))}
            </div>
          }
        </div>
      )}
    </span>
    </div>
  );
};

export default SharedPrompt;
