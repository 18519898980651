import React, { useEffect, useRef, useState } from "react";
import Icon from "components/Icon";
import { Link } from "react-router-dom";
import { useUsersContext } from "context/usersContext";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import DeleteChatModal from "components/DeleteChatModal";
import ShareChatModal from "components/ShareChatModal";
import EditChatModal from "components/EditChatModal";
import axios from "axios";
import env from "config";
const BASE_URL = `${env.API_URL}/v1`;

const Contact = ({ addChatCategoryHandle, categoryName, contact, onDelete, index, openDropdownIndex, setOpenDropdownIndex, favourites, getFavourites, chatCategories }) => {
  const { setUserAsUnread, showToast, setUsers, SSO, getLocationUsers, locationUsers, handleRefreshData } = useUsersContext();
  const history = useHistory();
  // alert(SSO?.id)

  const [askToDeleteModal, setAskToDeleteModal] = useState(false);
  const [askToShareModal, setAskToShareModal] = useState(false);
  const [selectedContact, setSelectedContact] = useState();
  const [EditModel, setEditModel] = useState(false)
  const isOpen = openDropdownIndex === index;
  const toggleDropdown = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setOpenDropdownIndex(isOpen ? null : index);
  };

  const askToDelete = async (e, contact) => {
    e.stopPropagation();
    e.preventDefault();
    setSelectedContact(contact);
    setAskToDeleteModal(true);
  }
  const askToShare = async (e, contact) => {
    e.stopPropagation();
    e.preventDefault();
    setSelectedContact(contact);
    setAskToShareModal(true);
  }

  // let parameter = useParams();
  const getLastMessage = () => {
    const messageDates = Object.keys(contact.messages);
    const recentMessageDate = messageDates[messageDates.length - 1];
    const messages = [...contact.messages[recentMessageDate]];
    const lastMessage = messages.pop();
    return lastMessage;
  };

  const handleDeleteClick = async () => {
    const deleteRes = await onDelete(selectedContact?.id);
    if (deleteRes.status === 200) {
      setUsers((users) => users.filter((user) => user.id !== selectedContact?.id));
      const redirectUrl = `/`;
      history.push(redirectUrl);
      justCloseIt();
    } else {
      showToast('Something went wrong!')
    }
  };

  const handleFavourite = async (e, contact) => {
    e.stopPropagation();
    e.preventDefault();
    const response = await axios.get(`${BASE_URL}/chat/add/favourite?chat_id=${contact?.id}&user_id=${SSO?.userUUID}&location_id=${SSO?.id}`);
    // await axios.get(`${BASE_URL}/remove/favourite?id=${contact?.id}`);
    if (response) {
      getFavourites();
      justCloseIt();
    }
  }

  const justCloseIt = () => {
    setAskToDeleteModal(false);
    setOpenDropdownIndex(false);
    setAskToShareModal(false);
    setEditModel(false)
  }

  const lastMessage = getLastMessage(contact);

  const truncateString = (str, numChars) => {
    if (str?.length > numChars) {
      return `${str?.slice(0, numChars)} ...`;
    }

    return str;
  };

  const moveToCategoryHandler = async (e, categoryId) => {
    const payload = {
      chatId: contact.id,
      category: categoryId,
    };
    return await axios
      .put(BASE_URL + "/chat/sharechat", payload)
      .then(async function (response) {
        handleRefreshData();
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const handleeditchat = () => {
    setEditModel(true);
    setSelectedContact(contact)
  }



  const truncatedMessage = truncateString(lastMessage?.content, 35);
  const truncatedName = truncateString(contact.name, 35);
  const isFavourite = favourites?.some(favourite => favourite.chat_id === contact.id);



  return (
    <>
      <Link
        className="sidebar-contact"
        to={`/chat/${contact.id}`}
        onClick={() => setUserAsUnread(contact.id)}
      >
        <div className="sidebar-contact__content">
          <div className="sidebar-contact__top-content">
            <h2 className="sidebar-contact__name"> {truncatedName} </h2>
            <p className="sidebar-contact__message-wrapper">
              {lastMessage?.status && (
                <Icon
                  id={
                    lastMessage?.status === "sent" ? "singleTick" : "doubleTick"
                  }
                  aria-label={lastMessage?.status}
                  className={`sidebar-contact__message-icon ${lastMessage?.status === "read"
                    ? "sidebar-contact__message-icon--blue"
                    : ""
                    }`}
                />
              )}
              <span
                className={`sidebar-contact__message ${!!contact.unread ? "sidebar-contact__message--unread" : ""
                  }`}
              >
                {/* <div dangerouslySetInnerHTML={{ __html: lastMessage?.content }} /> */}
                {contact.typing ? (
                  <i> typing...</i>
                ) : (
                  <div
                    className="limited-lines"
                    dangerouslySetInnerHTML={{ __html: truncatedMessage }}
                  />
                )}
              </span>
            </p>
          </div>
          <div className="sidebar-contact__bottom-content">
            <div className="sidebar-contact__time">
              <Dropdown
                EditFunction={handleeditchat}
                showDropdown={isOpen}
                toggleDropdown={toggleDropdown}
                onDeleteClick={(e) => askToDelete(e, contact)}
                onShareClick={(e) => askToShare(e, contact)}
                chatCategories={chatCategories}
                moveToCategoryHandler={moveToCategoryHandler}
                categoryName={categoryName}
                addChatCategoryHandle={addChatCategoryHandle}
                favbutton={(e) => handleFavourite(e, contact)}
                contactID={contact.id}
              //(e) => handleDeleteClick(e, contact.id)
              />
              {/* <span
                className="deleteChatBtn"
                onClick=
              >
                <Icon id={isFavourite ? 'filledStar' : 'star'} />
              </span> */}
            </div>
          </div>
        </div>
      </Link>

      <DeleteChatModal
        isModalOpen={askToDeleteModal}
        contact={selectedContact}
        yesDelete={handleDeleteClick}
        justCloseIt={justCloseIt}
        type="Chat"
      />
      <ShareChatModal
        isModalOpen={askToShareModal}
        contact={selectedContact}
        justCloseIt={justCloseIt}
        getLocationUsers={getLocationUsers}
        locationUsers={locationUsers}
        handleRefreshData={handleRefreshData}
      />
      <EditChatModal
        isModalOpen={EditModel}
        edit_data={selectedContact}
        justCloseIt={justCloseIt}
        refreshChatCategories={handleRefreshData}
      />
    </>
  );
};



const Dropdown = ({ EditFunction, favbutton, addChatCategoryHandle, categoryName, showDropdown, toggleDropdown, onDeleteClick, onShareClick, chatCategories, moveToCategoryHandler, contactID }) => {
  const [showSubmenu, setShowSubmenu] = useState(false);
  const dropdownRef = useRef(null);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });

  const toggleSubmenu = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setShowSubmenu(!showSubmenu);
  };

  const handleMenu = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const { clientX, clientY } = e;
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;
    const dropdownWidth = 200; // Approximate width of the dropdown menu
    const dropdownHeight = 150; // Approximate height of the dropdown menu

    let adjustedLeft = clientX;
    let adjustedTop = clientY;

    // Adjust horizontal position if the dropdown would overflow the viewport
    if (clientX + dropdownWidth > viewportWidth) {
      adjustedLeft = viewportWidth - dropdownWidth - 10; // 10px margin from the edge
    }

    // Adjust vertical position if the dropdown would overflow the viewport
    if (clientY + dropdownHeight > viewportHeight) {
      adjustedTop = viewportHeight - dropdownHeight - 10; // 10px margin from the edge
    }

    setDropdownPosition({ top: adjustedTop, left: adjustedLeft });
    toggleDropdown(e);
    setShowSubmenu(false);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      toggleDropdown(event);
    }
  };

  useEffect(() => {
    if (showDropdown) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showDropdown]);
  return (
    <span style={{ height: "100px", overflowY: "scroll" }} className="userChatIcon truncateUserName" Style={{ padding: 0 }} onClick={(e) => handleMenu(e)}>
      <Icon id="ellipsis" />
      {showDropdown && (
        <div ref={dropdownRef} className="settingMenu"
          style={{
            position: "fixed",
            top: dropdownPosition.top,
            left: dropdownPosition.left,
            zIndex: 1000,
            backgroundColor: "white",
            boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
            borderRadius: "4px",
          }}>

          <div style={{ padding: "5px 0px" }} onClick={EditFunction}>Rename</div>
          <div style={{ padding: "5px 0px" }} onClick={favbutton}>Favourite</div>
          <div style={{ padding: "5px 0px" }} onClick={onShareClick}>Share</div>
          <div style={{ padding: "5px 0px" }} onClick={toggleSubmenu}>{`Move >`}</div>
          <div style={{ padding: "5px 0px", color: "red" }} onClick={onDeleteClick}>Delete</div>

          {showSubmenu && (
            <div className="submenu">
              {/* Add your submenu items here */}
              {chatCategories.map((item, index) => (
                <>
                  {item.name !== "Uncategorised" && item.name !== categoryName && <div onClick={(e) => moveToCategoryHandler(e, item._id)}>{item.name}</div>}
                </>
              ))}
              <div onClick={() => addChatCategoryHandle(contactID)}> + Add new </div>
            </div>
          )}

        </div>
      )}
    </span>
  );
};

export default Contact;
