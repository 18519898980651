import React, { useState } from "react";
import Icon from "components/Icon";
import axios from "axios";
import { useLocation } from "react-router-dom";
import "./index.css";
import env from "config";
import { useUsersContext } from "context/usersContext";
const BASE_URL = `${env.API_URL}/v1`;

const Modal = ({ isOpen, onClose, children }) => {
  return (
    <>
      {isOpen && (
        <div className="modal-overlay" onClick={onClose}>
          <div
            className="modal-content-share text-center"
            onClick={(e) => e.stopPropagation()}
          >
            {children}
          </div>
        </div>
      )}
    </>
  );
};

const AddChatCategoryModal = ({
  isModalOpen,
  justCloseIt,
  refreshChatCategories,
  Oprationcontact
}) => {

  const { SSO, handleRefreshData } = useUsersContext();

  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState();
  const [categoryName, setCategoryName] = useState();

  const handleCategorySubmit = async () => {

    setProcessing(true);
    setError(false);

    if (categoryName === "" || categoryName === undefined) {
      setError(true);
      setProcessing(false);
      return false;
    }

    const response = await axios.post(`${BASE_URL}/chat/category`, {
      name: categoryName,
      userId: SSO?.userUUID,
      locationId: SSO?.id,
      type: "user",
    });

    setProcessing(false);
    if (response) {
      justCloseIt();
      move_Selected_chat_to_new_category(Oprationcontact, response.data.data._id);
      refreshChatCategories();
      setCategoryName(null);
    }
  };
  const move_Selected_chat_to_new_category = async (ContactID, categoryId) => {
    const payload = {
      chatId: ContactID,
      category: categoryId,
    };
    return await axios
      .put(BASE_URL + "/chat/sharechat", payload)
      .then(async function (response) {
        handleRefreshData();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  return (
    <div>
      <Modal isOpen={isModalOpen}>
        <div className="scrollable-modal-content">
          <div style={{ position: "relative" }}>
            <p
              style={{
                position: "absolute",
                right: 0,
                width: "12px",
                height: "12px",
                cursor: "pointer",
              }}
              onClick={justCloseIt}
            >
              <Icon id="close" />
            </p>
            <p className="modalText">Add Category</p>
            <div className="formGroup" style={{ marginBottom: "20px" }}>
              <label>Name</label>
              <input
                type="text"
                name="name"
                placeholder="Category Name"
                className="flex-1 modalInputs"
                onChange={(e) => setCategoryName(e.target.value)}
              />
              {error && (
                <small style={{ color: "red" }}>Category name required!</small>
              )}
            </div>
            <button
              className="disagreeBtn"
              style={{
                margin: 0,
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                opacity: processing ? 0.6 : 1,
              }}
              disabled={processing}
              onClick={handleCategorySubmit}
            >
              {processing && <div className="spinner"></div>}
              Save
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AddChatCategoryModal;
